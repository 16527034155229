// ENV
export const NODE_ENV = process.env.NODE_ENV

// THEMING
export const THEME_NAME = process.env.REACT_APP_THEME || 'default'

// PATHS
export const HQ_API_BASE_URL = "http://localhost:8000/"
export const ANY_API_BASE_URL = "http://localhost:8001/"
export const APP_BASE_URL = "/"

