import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import {THEME_NAME} from "./constants";
import './styles/index.scss';
import {Helmet, HelmetProvider} from 'react-helmet-async';

const App = React.lazy(() => import('./App'))
const AppDev = React.lazy(() => import('./AppDev'))
const AppRus = React.lazy(() => import('./AppRus'))

ReactDOM.render(
    <React.StrictMode>
        <HelmetProvider>
            <Helmet>
                <title>fm hq is loading ...</title>
            </Helmet>
            {THEME_NAME === "default" && <React.Suspense fallback="Loading..."><App/></React.Suspense>}
            {THEME_NAME === "dev" && <React.Suspense fallback="Loading..."><AppDev/></React.Suspense>}
            {THEME_NAME === "rus" && <React.Suspense fallback="Loading..."><AppRus/></React.Suspense>}
        </HelmetProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
